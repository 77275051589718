<template>
  <transition
    enter-active-class="transition transition-transform	duration-100 ease-out"
    enter-from-class="opacity-0"
    enter-to-class="opacity-100"
    leave-active-class="transition duration-100 ease-in"
    leave-from-class="opacity-100"
    leave-to-class="opacity-0"
  >
    <div class="fixed inset-x-0 z-50 px-2 top-4" v-if="visibleModel">
      <div
        class="max-w-xl mx-auto w-fit py-4 px-6 flex items-center rounded-lg shadow-sm"
        :class="clases[tipoModel]"
      >
        <component :is="iconos[tipoModel]" class="h-5 min-w-5 mr-3" />
        {{ mensajeModel }}
      </div>
    </div>
  </transition>
</template>

<script setup lang="ts">
  import { datosFeedback } from "@stores/app";
  import { useVModel } from "@nanostores/vue";
  import {
    CheckCircleIcon,
    XCircleIcon,
    ExclamationTriangleIcon,
    ExclamationCircleIcon,
  } from "@heroicons/vue/24/solid";

  const iconos = {
    success: CheckCircleIcon,
    error: XCircleIcon,
    warning: ExclamationTriangleIcon,
    info: ExclamationCircleIcon,
  };
  const clases = {
    success: "bg-green-50 text-green-500",
    error: "bg-red-50 text-red-500",
    warning: "bg-yellow-50 text-yellow-600",
    info: "bg-gray-50 text-gray-500",
  };

  const { mensajeModel, tipoModel, visibleModel } = useVModel(datosFeedback, [
    "mensaje",
    "tipo",
    "visible",
  ]);
</script>
